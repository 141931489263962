<template>
	<b-modal ref="modalEnvoie" hide-footer>
		<template v-slot:modal-title>
			{{ $t("courrier_type.ajout") }}
		</template>

		<e-select
            v-model="model_selected"
            id="models"
            track-by="model_label"
            label="model_label"
            class="primary"
            :options="models"
            :searchable="true"
            :show-labels="false"
            :allow-empty="false"
        >
            <template slot="singleLabel" slot-scope="{ option }">{{ option.model_label }}</template>
            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
        </e-select>

		<div class="mt-1">
			<b-form-checkbox id="with_paginations" v-model="with_paginations">{{ $t('courrier_type.print_with_pagination') }}</b-form-checkbox>
		</div>

        <div v-if="model_selected" class="text-center">
            <b-button pill variant="primary" class="mt-3" @click.prevent="addModelCourrier"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("courrier_type.choisir") }}</b-button>
        </div>
	</b-modal>
</template>

<script type="text/javascript">
	import { EventBus } from 'EventBus'
	import Model from "@/mixins/Model.js"

	export default {
		name: 'ModalContractEnvoie',
		mixins: [Model],
		data () {
			return {
				model_selected: {},
				models: [],
				processing: false,
				contracts_selected: [],
				with_paginations: true
			}
		},

		methods: {
			async openModal(contracts_selected) {
                const list_type = ['contract_envoie', 'contract_envoie_adresse']
                this.models = await this.loadAllModel(0, list_type)
                this.contracts_selected = contracts_selected
                this.$refs.modalEnvoie.show()
				EventBus.$emit("TableAction::stopSpin")
			},

			async addModelCourrier() {
				this.processing = true
				for(let i in this.contracts_selected) {
					const model = await this.createModel(this.contracts_selected[i], this.model_selected.type.modeltype_id, this.model_selected.model_label, 'contract_envoie')
					await this.cloneModel(this.model_selected.model_id, model.model_id)
					await this.generateCourrier(model.model_id, this.model_selected.type.modeltype_template, this.contracts_selected[i])
					await this.printCourrier(model.model_id, this.model_selected.model_label + "_" + this.contracts_selected[i], false, null, this.with_paginations)
				}
				this.processing = false
                this.$refs.modalEnvoie.hide()
			},
		}
	}

</script>